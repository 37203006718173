@import '../../../assets/styles/colors';
@import '../../../assets/styles/fonts';
@import '../../../assets/styles/mixins';
@import '../../../assets/styles/typography';

.trust {
  width: 100%;
  height: 92px;
  padding-top: 32px;
  border-top: solid 1px $ui-quaternary;
  cursor: pointer;
  @include media(m-device) {
    border: none;
  }
}